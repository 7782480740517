// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '@riseart/antd-provider';
import { Heading } from '@riseart/common';
import { FacebookButton } from 'shared_components/auth/facebook/Button';
import { GoogleButton } from 'shared_components/auth/google/Button';

import { socialContainerCls, socialContainerRowCls } from './Social.less';

type Props = {
  heading: any,
  align: 'horizontal' | 'vertical',
  setFacebookToken: Function,
  setGoogleToken: Function,
  isLogin?: boolean,
};

/**
 * SocialContainer
 */
export const SocialContainer = ({
  heading,
  align = 'horizontal',
  isLogin = true,
  setFacebookToken,
  setGoogleToken,
}: Props) => (
  <div className={socialContainerCls}>
    <Heading tag="h2" align="center">
      {heading || (
        <FormattedMessage id={`components.auth.${isLogin ? 'signin' : 'signup_social'}`} />
      )}
    </Heading>
    <Row type="flex" justify="center" align="top" gutter={4} className={socialContainerRowCls}>
      <Col xs={24} sm={align === 'vertical' ? 24 : 12}>
        <FacebookButton setToken={setFacebookToken}>
          <FormattedMessage
            id={`components.auth.${isLogin ? 'facebook_signin' : 'facebook_signup'}`}
          />
        </FacebookButton>
      </Col>
      <Col xs={24} sm={align === 'vertical' ? 24 : 12}>
        <GoogleButton setToken={setGoogleToken} text={isLogin ? 'signin_with' : 'signup_with'} />
      </Col>
    </Row>
  </div>
);
